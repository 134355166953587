.embla {
  position: relative;
}

.embla__viewport {
  width: 100%;
  overflow: hidden;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  will-change: transform;
  margin-left: -1rem;
}

.embla__slide {
  flex: 0 0 auto;
  width: 80%;
  position: relative;
  padding-left: 1rem;
  counter-increment: embla;
}

.embla__slide__inner__1, .embla__slide__inner__2, .embla__slide__inner__3, .embla__slide__inner__4, .embla__slide__inner__5, .embla__slide__inner__6 {
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 200px;
  position: relative;
  border-radius: 0.5rem;
  min-height: 200px;
  padding-bottom: 46%;
  font-size: 5rem;
}

.embla__slide__inner__1 {
  background-image: url(../assets/facebook1.jpg);
}

.embla__slide__inner__2 {
  background-image: url(../assets/facebook2.jpg);
}

.embla__slide__inner__3 {
  background-image: url(../assets/facebook3.jpg);
}

.embla__slide__inner__4 {
  background-image: url(../assets/facebook4.jpg);
}

.embla__slide__inner__5 {
  background-image: url(../assets/facebook5.jpg);
}

.embla__slide__inner__6 {
  background-image: url(../assets/facebook6.jpg);
}

.embla__slide__inner__1:before, .embla__slide__inner__2:before, .embla__slide__inner__3:before, .embla__slide__inner__4:before, .embla__slide__inner__5:before, .embla__slide__inner__6:before {
  color: white;
  font-weight: 300;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.embla__button {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.embla__button:not(:disabled) {
  cursor: pointer;
  fill: #ffffff;
}

.embla__button:disabled {
  fill: #e9e9e9;
}

.embla__button__svg {
  width: 3.5rem;
  height: 3.5rem;
}

.embla__button--prev {
  left: 0.7rem;
}

.embla__button--next {
  right: 0.7rem;
}

.embla__dots {
  position: absolute;
  margin-top: 1rem;
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: center;
  left: 0;
  right: 0;
  top: 100%;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border: 0;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #efefef;
  width: 100%;
  height: 0.4rem;
  content: "";
}

.embla__dot.is-selected:after {
  background-color: #000000;
  opacity: 1;
}
