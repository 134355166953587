html {
  font-size: 62.5%;
}

body {
  background-color: #7599fa;
  line-height: 1.5;
  font-size: 1.6rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

a, label {
  color: #ffffff;
  position: relative;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  width: 50%;
}

.header {
  font-size: xx-large;
  bottom: 10px;
}

.footer {
  position: relative;
  color: #ffffff;
  top: 60px;
  padding-left: 0px !important;
}

.content {
  background-color: #7599fa;
  padding-top: 3rem;
  padding-bottom: 5rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 80rem;
  width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 80rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.footer__text {
  margin-right: 1rem;
}

.footer__text__highlight {
  color: #1bcacd;
  font-weight: bold;
}

.footer__iframe {
  font-size: 0;
  height: 30px;
}
